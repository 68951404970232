export default {
  methods: {
    filterINN (s) {
      return s
        .split('')
        .filter((c) => c >= '0' && c <= '9')
        .join('')
    },

    calculateINNChecksum (inn) {
      // Рассчитать контрольное число ИНН:
      // а) для юрлица (10 цифр):
      //    n10 = ((2*n1 + 4*n2 + 10*n3 + 3*n4 + 5*n5 + 9*n6 + 4*n7 + 6*n8 + 8*n9) % 11) % 10
      // б) для физлица (12 цифр):
      //    n11 = ((7*n1 + 2*n2 + 4*n3 + 10*n4 + 3*n5 + 5*n6 + 9*n7 + 4*n8 + 6*n9 + 8*n10) % 11) % 10
      //    n12 = ((3*n1 + 7*n2 + 2*n3 + 4*n4 + 10*n5 + 3*n6 + 5*n7 + 9*n8 + 4*n9 + 6*n10 + 8*n11) % 11) % 10
      // Вернёт строку с паддингом нулями слева до нужного кол-ва знаков!
      const a = inn.split('').map((c) => c.charCodeAt(0) - 48)
      if (a.length === 10) {
        // Юрлицо.
        const n10 = ((2 * a[0] + 4 * a[1] + 10 * a[2] + 3 * a[3] + 5 * a[4] + 9 * a[5] + 4 * a[6] + 6 * a[7] + 8 * a[8]) % 11) % 10
        return ('' + n10).padStart(1, '0')
      }
      if (a.length === 12) {
        const n11 = ((7 * a[0] + 2 * a[1] + 4 * a[2] + 10 * a[3] + 3 * a[4] + 5 * a[5] + 9 * a[6] + 4 * a[7] + 6 * a[8] + 8 * a[9]) % 11) % 10
        const n12 = ((3 * a[0] + 7 * a[1] + 2 * a[2] + 4 * a[3] + 10 * a[4] + 3 * a[5] + 5 * a[6] + 9 * a[7] + 4 * a[8] + 6 * a[9] + 8 * a[10]) % 11) % 10
        return ('' + n11 + n12).padStart(2, '0')
      }
    },

    validateINN (value) {
      if (value === '') {
        // Пустое поле не проверяем, флаг валидности обнуляем.
        return true
      } else {
        value = this.filterINN(value)

        if (value.length > 12) {
          return 'В ИНН должно быть не больше 12 символов'
        } else {
          const checksum = this.calculateINNChecksum(value)
          if (!value.endsWith(checksum)) {
            // Введённое контрольное число не совпало с расчётным.
            return 'Проверьте ИНН, в нём ошибка: не сходится контрольное число'
          } else {
            // Контрольное число совпало, ИНН валиден.
            return true
          }
        }
      }
    }
  },
}
